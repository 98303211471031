import * as React from 'react';
import ReactAI from 'react-appinsights';

export interface ErrorBoundaryState {
  hasError: boolean;
}
export interface ErrorBoundaryProps {
  redirectOnError?: boolean;
}

// this is to get require working nicely
export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  redirectOnError: boolean;
  

  public constructor(props: ErrorBoundaryProps = {}) {
    super(props);

    this.redirectOnError = props.redirectOnError ?? true;
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(_error: Error) {
    // Update state so the next render will show the fallback UI.
    return {hasError: true};
  }

  public componentDidCatch(error: Error) {
    const appInsights = ReactAI.ai();
    appInsights.trackException(error);

    if (this.redirectOnError) {
      document.location.assign('/error');
    }
  }

  public render() {
    if (this.state.hasError) {
      return null;
    }

    return this.props.children;
  }
}
