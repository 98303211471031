/* eslint-disable @typescript-eslint/no-var-requires */
const React = require('react');
const ReactAI = require('react-appinsights');
const ReachRouter = require('@reach/router');
const objectFitImages = require('@mfb/object-fit-images-polyfil');
require('custom-event-polyfill');
const {ErrorBoundary} = require('./ErrorBoundary');
const {LegoThemeProvider} = require('@mfb/lego');

exports.onClientEntry = () => {
  const history = ReachRouter.createHistory(window);
  let gtmContainerId = process.env.GATSBY_GA_TRACKING_CONTAINER_ID_MFB;

  switch (process.env.GATSBY_BRAND){
    case 'BB':
      gtmContainerId = process.env.GATSBY_GA_TRACKING_CONTAINER_ID_BB
      break;
    case 'FS':
      gtmContainerId = process.env.GATSBY_GA_TRACKING_CONTAINER_ID_FS
      break;
    default:
      gtmContainerId = process.env.GATSBY_GA_TRACKING_CONTAINER_ID_MFB
  }

(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
`${process.env.GATSBY_GTM_URL}/gtm.js?id=`+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer',gtmContainerId);

  ReactAI.init(
    {instrumentationKey: process.env.APPLICATION_INSIGHTS_KEY},
    history
  );

  ReactAI.setAppContext({urlReferrer: window.document.referrer});
};

exports.onRouteUpdate = ({location}) => {
  const appInsights = ReactAI.ai();
  appInsights.trackPageView(location.pathname);
};

// eslint-disable-next-line react/display-name
exports.wrapRootElement = ({element}) => (
  <LegoThemeProvider brand={process.env.GATSBY_BRAND}>
    <ErrorBoundary>{element}</ErrorBoundary>
  </LegoThemeProvider>
);

exports.onInitialClientRender = () => {
  objectFitImages();
};
